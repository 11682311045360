import { mq, typography } from "styles"
import styled, { css } from "styled-components"
import { $Positioned } from "typings/modules"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"

const getToolTipPositionStyles = (leftPercent: number, topPercent: number) => {
  const xStyle =
    leftPercent <= 50
      ? `left: ${Math.max(0, leftPercent)}%;`
      : `right: ${Math.max(100 - leftPercent)}%;`
  const yStyle =
    topPercent <= 50 ? `top: ${topPercent}%;` : `bottom: ${100 - topPercent}%;`

  return xStyle + "\n" + yStyle
}

const hotspotContentStyle = css`
  color: var(--hotspot-tooltip-text);
  background-color: var(--hotspot-tooltip-fill);
  border: 1px solid var(--hotspot-tooltip-stroke);
  padding: 16px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: var(--hotspot-tooltip-text);
  }
`

export const HotspotTooltip = styled.div<$Positioned & { $selected: boolean }>`
  ${hotspotContentStyle}
  visibility: hidden;
  position: absolute;
  ${({ $xPercent, $yPercent }) =>
    getToolTipPositionStyles($xPercent, $yPercent)};
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: var(--hotspot-rounding);
  &:hover {
    visibility: visible;
    opacity: 1;
  }

  ${({ $selected }) =>
    $selected &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  div p {
    ${typography.bodySmall};
  }
`

export const HotspotModalContentContainer = styled.div`
  ${hotspotContentStyle}
  flex-grow: 1;
`

const hotspotHighlightedCss = css`
  border: 1px solid var(--hotspot-hover-stroke);
  background-color: var(--hotspot-hover-fill);
  color: var(--hotspot-hover-text);
  ${mq.minWidth("sm")} {
    + ${HotspotTooltip} {
      visibility: visible;
      opacity: 1;
    }
  }
`
export const HotspotButton = styled(NoStyleButton)<$Positioned>`
  ${typography.caption};
  position: absolute;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 1px solid var(--hotspot-inactive-stroke);
  background-color: var(--hotspot-inactive-fill);
  color: var(--hotspot-inactive-text);
  user-select: none;
  left: ${({ $xPercent }) => $xPercent}%;
  top: ${({ $yPercent }) => $yPercent}%;
  transform: translate(-50%, -50%);
  display: flex;
  align-content: space-around;
  align-items: center;
  justify-content: center;
  cursor: default;
  &:hover,
  &.selected {
    ${hotspotHighlightedCss}
  }
  ${mq.maxWidth("md")} {
    width: 24px;
    height: 24px;
  }
`

export const HotspotWrapper = styled.div<{ $selected?: boolean }>`
  ${mq.minWidth("sm")} {
    &:hover ${HotspotButton} {
      z-index: 2;
      ${hotspotHighlightedCss}
    }
    ${({ $selected }) =>
      $selected &&
      css`
        ${HotspotButton} {
          z-index: 2;
          ${hotspotHighlightedCss}
        }
      `}
  }
`

export const ProductHotspotContainer = styled.div`
  ${mq.minWidth("sm")} {
    width: 512px;
  }
`

export const TextHotspotContainer = styled.div`
  a {
    color: var(--hotspot-tooltip-text);
    border-bottom: 1px solid var(--hotspot-tooltip-text);
  }
  ${mq.minWidth("sm")} {
    max-width: 300px;
  }
`
