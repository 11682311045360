import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { VertAlignType, HorizAlignType, HeroItemProps } from "typings/modules"
import { useCommonMediaQuery } from "hooks/useMediaQuery"
import { HeroText } from "./HeroText"
import {
  Container,
  ContentContainer,
  ImageContainer,
  ShopThisLookButton,
} from "./HeroAutoLayout.styles"
import { GridContainer } from "./common.styles"
import { getImage } from "utils/imageUtils"
import { SanityImageAsset, SanityHotspotAsset } from "typings/graphql"
import { generateAltContentIndex } from "utils/altTextUtils"
import Hotspot from "components/Hotspot/Hotspot"
import { HotspotModal } from "components/Hotspot/HotspotModal"
import { ProductFlyout } from "components/ProductFlyout/ProductFlyout"
import { isProductHotspot } from "components/Hotspot/HotspotTypeguards"
import {
  LargeAndUp,
  MediumAndBelow,
} from "components/UI/MediaQueryContainers/MediaQueryContainers"

const isHotspotAsset = (something: any): something is SanityHotspotAsset => {
  return something?._type === "hotspotAsset"
}

const getAssets = (someAsset: any) => {
  if (isHotspotAsset(someAsset)) {
    return {
      imageAsset: someAsset.imageAsset as SanityImageAsset,
      hotspots: someAsset.hotspots,
      hideShopThisLookCTA: someAsset.hideShopThisLookCTA,
    }
  } else {
    return { imageAsset: someAsset as SanityImageAsset }
  }
}
const HeroAutoImage: React.FC<{
  imageAsset: SanityImageAsset
  fallbackAlt: string
  firstItem: boolean
}> = ({ fallbackAlt, imageAsset, firstItem }) => {
  const image = getImage(imageAsset?.image)
  const imageAlt = image?.description ?? fallbackAlt

  return (
    <ImageContainer>
      {image && (
        <GatsbyImage
          alt={imageAlt}
          image={image?.gatsbyImage}
          loading={firstItem ? "eager" : "lazy"}
        />
      )}
    </ImageContainer>
  )
}

export const HeroAutoLayout = ({
  item,
  index,
  $mode,
  $mobileMode,
}: HeroItemProps) => {
  const { isMobile } = useCommonMediaQuery()
  const {
    imageAsset: mobileAsset,
    hotspots: mobileHotspots,
    hideShopThisLookCTA: hideMobileShopThisLookCTA,
  } = getAssets(item?.asset?.mobileAsset[0])
  const {
    imageAsset: desktopAsset,
    hotspots: desktopHotspots,
    hideShopThisLookCTA: hideDesktopShopThisLookCTA,
  } = getAssets(item?.asset?.desktopAsset[0])

  const mobileImage = getImage(mobileAsset?.image)
  const hotspots = isMobile ? mobileHotspots : desktopHotspots

  const fallbackAlt = generateAltContentIndex(
    item?._rawContent,
    index,
    item?.internalName
  )
  const imageAlt = mobileImage?.description ?? fallbackAlt
  const bgColor = item?.bgColor?.opacityHex
  const desktopAlign = (item?.desktopAlign ?? "center") as HorizAlignType
  const mobileAlign = (item?.mobileAlign ?? "center") as VertAlignType
  const hotspotProducts = hotspots
    ?.map(hotspot => hotspot.hotspotContent[0])
    .filter(isProductHotspot)
    .map(productHotspot => productHotspot.productItem)

  const [selectedHotspotKey, setSelectedHotspotKey] = useState<string>("")
  const [hotspotProductFlyoutOpen, setHotspotProductFlyoutOpen] = useState(
    false
  )
  const firstItem = index === 0
  const hideShopThisLookCTA = isMobile
    ? hideMobileShopThisLookCTA
    : hideDesktopShopThisLookCTA

  return (
    <Container $bgColor={bgColor}>
      <MediumAndBelow>
        <HeroAutoImage
          imageAsset={mobileAsset}
          firstItem={firstItem}
          fallbackAlt={fallbackAlt}
        />
      </MediumAndBelow>
      <LargeAndUp>
        <HeroAutoImage
          imageAsset={desktopAsset}
          firstItem={firstItem}
          fallbackAlt={fallbackAlt}
        />
      </LargeAndUp>
      <ContentContainer $vertAlign={mobileAlign}>
        <GridContainer $horizAlign={desktopAlign}>
          <HeroText item={item} $mode={$mode} $mobileMode={$mobileMode} />
        </GridContainer>

        {hotspots?.map(hotspot => (
          <Hotspot
            hotspot={hotspot}
            key={hotspot._key}
            onHotspotClick={() =>
              setSelectedHotspotKey(
                selectedHotspotKey === hotspot._key ? "" : hotspot._key
              )
            }
            selected={hotspot._key === selectedHotspotKey}
          />
        ))}
        {!!hotspotProducts?.length && !hideShopThisLookCTA ? (
          <ShopThisLookButton
            trackingId="hotspot-shop-all-btn"
            onClick={() => setHotspotProductFlyoutOpen(true)}
          >
            Shop This Look
          </ShopThisLookButton>
        ) : null}
      </ContentContainer>
      {isMobile && selectedHotspotKey && (
        <HotspotModal
          image={mobileImage}
          hotspots={hotspots}
          altText={imageAlt}
          initialSelectedHotspotKey={selectedHotspotKey}
          onModalClose={() => setSelectedHotspotKey(null)}
        />
      )}
      {!!hotspotProducts?.length && (
        <ProductFlyout
          isOpen={hotspotProductFlyoutOpen}
          onClose={() => setHotspotProductFlyoutOpen(false)}
          productItems={hotspotProducts}
        />
      )}
    </Container>
  )
}

export default HeroAutoLayout
