import React from "react"
import { QuickAddProductItem } from "components/ProductItem/QuickAddProductItem"
import { PortableText } from "components/UI/PortableText/PortableText"
import { useProductList } from "hooks/useProductList"
import {
  SanityProductHotspotOrTextHotspot,
  SanityProductHotspot,
} from "typings/graphql"
import { isProductHotspot } from "./HotspotTypeguards"
import { ProductHotspotContainer, TextHotspotContainer } from "./Hotspot.styles"

type ProductHotspotContentProps = {
  productHotspot: SanityProductHotspot
  onModalClose?: () => void
}

const ProductHotspotContent: React.FC<ProductHotspotContentProps> = ({
  productHotspot,
  onModalClose,
}) => {
  const {
    plItems: [hotspotProductItem],
    loading,
  } = useProductList([productHotspot.productItem])

  return (
    <>
      {!loading && hotspotProductItem && (
        <QuickAddProductItem
          preAddToCartClick={() => onModalClose?.()}
          item={hotspotProductItem}
          mode="dark"
        />
      )}
    </>
  )
}

type HotspotContentProps = {
  hotspotContent: SanityProductHotspotOrTextHotspot
  onModalClose?: () => void
}

export const HotspotContent: React.FC<HotspotContentProps> = ({
  hotspotContent,
  onModalClose,
}) => {
  if (isProductHotspot(hotspotContent)) {
    return (
      <ProductHotspotContainer>
        <ProductHotspotContent
          onModalClose={onModalClose}
          productHotspot={hotspotContent}
        />
      </ProductHotspotContainer>
    )
  } else {
    return (
      <TextHotspotContainer>
        <PortableText blocks={hotspotContent?._rawContent} />
      </TextHotspotContainer>
    )
  }
}
