import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState, useRef } from "react"
import { SanityHotspot } from "typings/graphql"
import { Container, ImageContainer } from "./HotspotModal.styles"
import Hotspot from "./Hotspot"
import { IGatsbyImageDataWithDesc } from "typings/modules"
import Modal from "components/Modal/Modal"
import { HotspotModalContentContainer } from "./Hotspot.styles"
import { HotspotContent } from "./HotspotContent"

type HotspotModalProps = {
  image: IGatsbyImageDataWithDesc
  altText: string
  hotspots: SanityHotspot[]
  initialSelectedHotspotKey: string
  onModalClose: () => void
}

export const HotspotModal = ({
  image,
  altText,
  hotspots,
  initialSelectedHotspotKey,
  onModalClose,
}: HotspotModalProps) => {
  const aspectRatio = image.gatsbyImage.width / image.gatsbyImage.height
  const tooltipRef = useRef<HTMLDivElement>()

  const [selectedHotspotKey, setSelectedHotspotKey] = useState<string>(
    initialSelectedHotspotKey
  )
  const selectedHotspot = hotspots.find(
    hotspot => hotspot._key === selectedHotspotKey
  )

  return (
    <Modal onModalClose={onModalClose} scrollableRef={tooltipRef}>
      <Container>
        <ImageContainer $aspectRatio={aspectRatio}>
          <GatsbyImage alt={altText} image={image.gatsbyImage} />
          {hotspots.map(hotspot => (
            <Hotspot
              hotspot={hotspot}
              key={hotspot._key}
              selected={hotspot._key === selectedHotspotKey}
              onHotspotClick={() => setSelectedHotspotKey(hotspot._key)}
            />
          ))}
        </ImageContainer>
        <HotspotModalContentContainer ref={tooltipRef}>
          <HotspotContent
            onModalClose={onModalClose}
            hotspotContent={selectedHotspot.hotspotContent[0]}
          />
        </HotspotModalContentContainer>
      </Container>
    </Modal>
  )
}
