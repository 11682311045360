import React from "react"
import { HotspotButton, HotspotWrapper, HotspotTooltip } from "./Hotspot.styles"
import { SanityHotspot } from "typings/graphql"
import { useCommonMediaQuery } from "hooks/useMediaQuery"
import { tracking } from "utils/tracking"
import { HotspotContent } from "./HotspotContent"

type HotspotProps = {
  hotspot: SanityHotspot
  selected?: boolean
  onHotspotClick?: () => void
}

type MobileHotspotProps = HotspotProps & {
  onHotspotClick: () => void
}

const MobileHotspot = ({
  hotspot,
  selected,
  onHotspotClick,
}: MobileHotspotProps) => {
  const handleMobileHotspotClick = () => {
    tracking.elementClicked(`hotspot-${hotspot.internalName}`)
    tracking.elementViewed(`hotspot-description-${hotspot.internalName}`)

    onHotspotClick?.()
  }

  return (
    <HotspotWrapper>
      <HotspotButton
        aria-label={`Hotspot number ${hotspot?.internalName}`}
        className={selected ? "selected" : ""}
        $xPercent={hotspot.xPercent}
        $yPercent={hotspot.yPercent}
        onClick={handleMobileHotspotClick}
      >
        {hotspot.character}
      </HotspotButton>
    </HotspotWrapper>
  )
}

const DesktopHotspot = ({
  hotspot,
  selected,
  onHotspotClick,
}: HotspotProps) => {
  const handleHotspotHover = () => {
    tracking.elementViewed(`hotspot-description-${hotspot.internalName}`)
  }

  const handleDesktopHotspotClick = () => {
    tracking.elementClicked(`hotspot-${hotspot.internalName}`)
    tracking.elementViewed(`hotspot-description-${hotspot.internalName}`)

    onHotspotClick?.()
  }

  return (
    <HotspotWrapper $selected={selected}>
      <HotspotButton
        aria-label={`Hotspot button ${hotspot?.internalName}`}
        className={selected ? "selected" : ""}
        $xPercent={hotspot.xPercent}
        $yPercent={hotspot.yPercent}
        onMouseEnter={handleHotspotHover}
        onClick={handleDesktopHotspotClick}
      >
        {hotspot.character}
      </HotspotButton>
      <HotspotTooltip
        $xPercent={hotspot.xPercent}
        $yPercent={hotspot.yPercent}
        $selected={selected}
      >
        <HotspotContent hotspotContent={hotspot.hotspotContent[0]} />
      </HotspotTooltip>
    </HotspotWrapper>
  )
}

export const Hotspot = ({
  hotspot,
  selected,
  onHotspotClick,
}: HotspotProps) => {
  const { isMobile } = useCommonMediaQuery()

  return isMobile ? (
    <MobileHotspot
      hotspot={hotspot}
      selected={selected}
      onHotspotClick={onHotspotClick}
    />
  ) : (
    <DesktopHotspot
      hotspot={hotspot}
      selected={selected}
      onHotspotClick={onHotspotClick}
    />
  )
}

export default Hotspot
