import styled from "styled-components"

type $AspectRatio = {
  $aspectRatio?: number
}

const modalTooltipMinHeight = 300

export const Container = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--hotspot-inactive-fill);
`

export const ImageContainer = styled.div<$AspectRatio>`
  position: relative;
  width: ${({ $aspectRatio }) =>
    `calc((100vh - ${modalTooltipMinHeight}px) * ${$aspectRatio})`};
  max-width: 100vw;
  max-height: calc(100vh - ${modalTooltipMinHeight}px);
`
