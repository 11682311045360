import { mq } from "styles"
import styled, { css } from "styled-components"
import { $BgColor, VertAlign } from "typings/modules"
import { Button } from "components/UI/Button/Button"

export const Container = styled.div<$BgColor>`
  background: ${({ $bgColor }) => $bgColor};
  position: relative;
  display: grid;
  width: 100%;
`

const contentFullStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: 64px 0;
  ${mq.minWidth("md")} {
    padding: 96px 0;
  }
  ${mq.minWidth("lg")} {
    align-content: center;
  }
`

const calcVertAlign = ({ $vertAlign }: VertAlign) => {
  if ($vertAlign === "top") return "flex-start"
  if ($vertAlign === "bottom") return "flex-end"
  return "center"
}

const vertAlignStyles = ({ $vertAlign }: VertAlign) => css`
  align-content: ${calcVertAlign({ $vertAlign })};
`

export const ContentContainer = styled.div<VertAlign>`
  display: grid;
  ${vertAlignStyles};
  grid-template-columns: repeat(12, 1fr);
  ${contentFullStyles};
  ${mq.minWidth("lg")} {
    grid-template-columns: repeat(24, 1fr);
  }
`

export const ImageContainer = styled.div`
  .gatsby-image-wrapper {
    display: block;
    position: relative;
  }
`

export const ShopThisLookButton = styled(Button)`
  position: absolute;

  right: 16px;
  bottom: 16px;

  ${mq.minWidth("sm")} {
    right: 24px;
    bottom: 24px;
  }

  ${mq.minWidth("lg")} {
    right: 32px;
    bottom: 32px;
  }
`
