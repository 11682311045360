import {
  SanityProductHotspotOrTextHotspot,
  SanityProductHotspot,
} from "typings/graphql"

export const isProductHotspot = (
  hotspot: SanityProductHotspotOrTextHotspot
): hotspot is SanityProductHotspot => {
  return hotspot?._type === "productHotspot"
}
